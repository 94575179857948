import React from 'react';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';

import Button from '../../../components/Button';
import { ACT_BTN_TEXT, TIMEKEEPING_STATUSES } from '../constants';

import { isShiftCompleted } from './helperFunctions';

export const showApproveBtn = (status, shiftEnd, userRole, onClick) =>
  isShiftCompleted(shiftEnd) &&
  (status === TIMEKEEPING_STATUSES.PENDING || status === TIMEKEEPING_STATUSES.DISPUTED) &&
  userRole === UserRole.EMPLOYER ? (
    <Button
      dataTestId="update-status-approve-btn"
      text={ACT_BTN_TEXT.APPROVE}
      onClick={onClick}
      sx={{ ml: '32px' }}
    />
  ) : null;

export const showResolveBtn = (status, shiftEnd, userRole, onClick) =>
  isShiftCompleted(shiftEnd) &&
  (status === TIMEKEEPING_STATUSES.PENDING || status === TIMEKEEPING_STATUSES.DISPUTED) &&
  userRole === UserRole.ADMIN ? (
    <Button
      dataTestId="update-status-resolve-btn"
      text={ACT_BTN_TEXT.RESOLVE}
      onClick={onClick}
      sx={{ ml: '32px' }}
    />
  ) : null;

export const showFinalizeBtn = (status, shiftEnd, userRole, onClick) =>
  isShiftCompleted(shiftEnd) &&
  (status === TIMEKEEPING_STATUSES.APPROVED || status === TIMEKEEPING_STATUSES.RESOLVED) &&
  userRole === UserRole.ADMIN ? (
    <Button
      dataTestId="update-status-finalize-btn"
      text={ACT_BTN_TEXT.FINALIZE}
      onClick={onClick}
      sx={{ ml: '32px' }}
    />
  ) : null;

export const showUnFinalizeBtn = (status, shiftEnd, userRole, onClick) =>
  isShiftCompleted(shiftEnd) &&
  status === TIMEKEEPING_STATUSES.FINALIZED &&
  userRole === UserRole.ADMIN ? (
    <Button
      dataTestId="update-status-unfinalize-btn"
      text={ACT_BTN_TEXT.UNFINALIZE}
      onClick={onClick}
      sx={{ ml: '32px' }}
    />
  ) : null;
