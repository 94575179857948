import React from 'react';
import PropTypes from 'prop-types';
import { Form as RFForm } from 'react-final-form';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import Button, { BUTTON_TYPE } from '../../../../components/Button';
import theme from '../../../../theme';

import AdminAdditionalDetailsFormBody from './AdminAdditionalDetailsFormBody';

const AdminAdditionalDetails = ({
  initialValues,
  onUpdate,
  requireEmployerApproval,
  allowBackfill,
  overtimeAllowed,
  gpsEnforced,
  punchDistance,
}) => {
  const StyledFixedBottomBox = styled(Box)(() => ({
    position: 'absolute',
    backgroundImage: `linear-gradient(81.37deg, ${theme.button.palette.primary} 33.18%, ${theme.button.palette.secondary} 94.14%)`,
    fontFamily: `${theme.button.fontFamily}`,
    color: '#FFF',
    width: '100%',
    left: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '4.40vh',
    cursor: 'pointer',
    borderBottomLeftRadius: '13px',
    borderBottomRightRadius: '13px',
  }));

  const adjustedInitialValues = {
    ...initialValues,
    requireEmployerApproval,
    allowBackfill,
    overtimeAllowed,
    gpsEnforced,
    punchDistance,
  };

  const componentsDetailViewSectionHeadersStyle =
    theme?.components?.detailView?.typography?.sectionHeader;

  const viewHeaderStyling = {
    fontSize: componentsDetailViewSectionHeadersStyle?.fontSize,
    fontFamily: componentsDetailViewSectionHeadersStyle?.fontFamily,
    color: componentsDetailViewSectionHeadersStyle?.fontColor,
    margin: theme.spacing(0, 0, 0, 1),
  };

  return (
    <RFForm
      onSubmit={(values) => {
        onUpdate(values, initialValues);
      }}
      initialValues={adjustedInitialValues}
      render={({ handleSubmit, pristine }) => (
        <Box>
          <Typography sx={viewHeaderStyling}>
            {LanguageConverter('pt.additionalDetails')}
          </Typography>
          <Box
            sx={{
              width: '100%',
              overflowY: 'auto',
              '&::-webkit-scrollbar': { display: 'none' },
              height: '80vh',
            }}
          >
            <form
              onSubmit={handleSubmit}
              id="loginformid"
              style={{
                width: '100%',
              }}
            >
              <AdminAdditionalDetailsFormBody />

              {pristine ? null : (
                <StyledFixedBottomBox>
                  <Button
                    sx={{ width: '100%' }}
                    type={BUTTON_TYPE.SUBMIT}
                    text={LanguageConverter('Submit')}
                  />
                </StyledFixedBottomBox>
              )}
            </form>
          </Box>
        </Box>
      )}
    />
  );
};

AdminAdditionalDetails.propTypes = {
  initialValues: PropTypes.shape({}),
  onUpdate: PropTypes.func,
  requireEmployerApproval: PropTypes.bool,
  allowBackfill: PropTypes.bool,
  overtimeAllowed: PropTypes.bool,
  gpsEnforced: PropTypes.bool,
  punchDistance: PropTypes.number,
};

export default AdminAdditionalDetails;
