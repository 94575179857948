import React from 'react';
import PropTypes from 'prop-types';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';

import theme from '../../../../theme';
import { isShiftCompleted } from '../../../../utils';
import { TIMEKEEPING_STATUSES } from '../../../../views/jobOrders/jobOrderConstants';
import WaeButton, { BUTTON_VARIANT } from '../../../Button';

const cardTheme = theme.jobOrders.detail.roster.employeeRosterCard;
const btnStyles = {
  borderRadius: '5%',
  height: '25px',
  width: '100px',
  marginLeft: '10px',
};

const ActionBtn = ({ onClick, dataTestId, btnText }) => (
  <WaeButton
    actionColor={cardTheme.actionButton.actionColor}
    variant={BUTTON_VARIANT.OUTLINED}
    sx={btnStyles}
    borderColor={cardTheme.actionButton.borderColor}
    hoverColor={cardTheme.actionButton.hoverColor}
    dataTestId={dataTestId}
    onClick={onClick}
    text={LanguageConverter(btnText)}
  />
);
ActionBtn.propTypes = {
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
  btnText: PropTypes.string,
};

export const showApproveBtn = (status, shiftEnd, userRole, onClick) =>
  isShiftCompleted(shiftEnd) &&
  (status === TIMEKEEPING_STATUSES.PENDING || status === TIMEKEEPING_STATUSES.DISPUTED) &&
  userRole === UserRole.EMPLOYER ? (
    <ActionBtn
      onClick={onClick}
      dataTestId="update-status-approve-btn"
      btnText="buttonText.approve"
    />
  ) : null;

export const showResolveBtn = (status, shiftEnd, userRole, onClick) =>
  isShiftCompleted(shiftEnd) &&
  (status === TIMEKEEPING_STATUSES.PENDING || status === TIMEKEEPING_STATUSES.DISPUTED) &&
  userRole === UserRole.ADMIN ? (
    <ActionBtn
      onClick={onClick}
      dataTestId="update-status-resolve-btn"
      btnText="buttonText.resolve"
    />
  ) : null;

export const showFinalizeBtn = (status, shiftEnd, userRole, onClick) =>
  isShiftCompleted(shiftEnd) &&
  (status === TIMEKEEPING_STATUSES.APPROVED || status === TIMEKEEPING_STATUSES.RESOLVED) &&
  userRole === UserRole.ADMIN ? (
    <ActionBtn
      onClick={onClick}
      dataTestId="update-status-finalize-btn"
      btnText="buttonText.finalize"
    />
  ) : null;

export const showUnFinalizeBtn = (status, shiftEnd, userRole, onClick) =>
  isShiftCompleted(shiftEnd) &&
  status === TIMEKEEPING_STATUSES.FINALIZED &&
  userRole === UserRole.ADMIN ? (
    <ActionBtn
      onClick={onClick}
      dataTestId="update-status-unfinalize-btn"
      btnText="buttonText.unFinalize"
    />
  ) : null;
