import * as React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker as ResponsiveTimePicker } from '@mui/x-date-pickers/TimePicker';

import theme from '../../theme';

const TimePickerField = ({
  error,
  fullWidth,
  inputAdornmentProps,
  label,
  minTime,
  mode,
  name,
  onChange,
  onClose,
  readOnly,
  required,
  sx,
  value,
  variant,
}) => {
  let textFieldBackground;
  let textFieldBackgroundHover;
  let textFieldFocusedBorder;
  let textFieldErrorBorder;
  let textFieldPrimaryLabel;
  switch (mode) {
    case 'dark':
      // FIXME: Replace when dark mode colors are out

      textFieldBackground = get(['textField', 'darkBkColor'], theme);
      textFieldBackgroundHover = get(['palette', 'secondary', 'light'], theme);
      textFieldFocusedBorder = get(['textField', 'borderColor', 'focused'], theme);
      textFieldErrorBorder = get(['textField', 'borderColor', 'error'], theme);
      textFieldPrimaryLabel = get(['textField', 'inputLabel', 'primary', 'light'], theme);

      break;
    default:
      textFieldBackground = get(['textField', 'inputLabel', 'primary', 'light'], theme);
      textFieldBackgroundHover = get(['textField', 'background', 'light'], theme);
      textFieldFocusedBorder = get(['textField', 'borderColor', 'focused'], theme);
      textFieldErrorBorder = get(['textField', 'borderColor', 'error'], theme);
      textFieldPrimaryLabel = get(['textField', 'inputLabel', 'primary', 'focused'], theme);

      break;
  }

  const styleForTextField = {
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
    '& .MuiOutlinedInput-root': {
      backgroundColor: textFieldBackground,
      borderRadius: '40px',
      fontFamily: 'Barlow',
      '&:hover': {
        backgroundColor: textFieldBackgroundHover,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      outline: `1px solid ${textFieldFocusedBorder}`,
      backgroundColor: textFieldBackground,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      outline: `1px solid ${textFieldErrorBorder}`,
    },
    '& .MuiInputLabel-root': {
      top: '-8px',
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1, 7, 1, 2),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiFormLabel-asterisk.Mui-error': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputBase-root': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputBase-root.Mui-focused': {
      fontWeight: 500,
    },
    '& .MuiInputAdornment-root svg': {
      color: textFieldPrimaryLabel,
    },
    ...sx,
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} component="span">
      <Stack component="span">
        <ResponsiveTimePicker
          desktopModeMediaQuery="@media (min-width: 900px)" // TODO: HB-952 discuss min-width
          InputAdornmentProps={inputAdornmentProps}
          label={label}
          name={name}
          minTime={minTime}
          onClose={onClose}
          onChange={onChange}
          readOnly={readOnly}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              fullWidth={fullWidth}
              helperText={error || null}
              required={required}
              sx={styleForTextField}
              variant={variant}
            />
          )}
          value={value}
        />
      </Stack>
    </LocalizationProvider>
  );
};
TimePickerField.propTypes = {
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  inputAdornmentProps: PropTypes.shape({}),
  label: PropTypes.string,
  mode: PropTypes.string,
  minTime: PropTypes.instanceOf(Date),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  sx: PropTypes.shape({}),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  variant: PropTypes.string,
};
export default TimePickerField;
