import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { searchParamOptions } from '../../../main/views/app/routeConstants';
import theme from '../../theme';
import { getAllSearchParams, isSearchParamValid } from '../../utils';

const TabbedPages = ({ data }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get(searchParamOptions.ACTIVE_TAB);
  const tabIndex = isSearchParamValid(activeTab) ? parseInt(activeTab, 10) : 0;

  const handleChange = (event, newValue) => {
    const params = getAllSearchParams(searchParams);
    params[searchParamOptions.ACTIVE_TAB] = newValue;
    setSearchParams(params);
  };

  const tabContent = () => {
    if (!get('tabContent', data[tabIndex])) {
      handleChange(0);
    }
    return get('tabContent', data[tabIndex]);
  };

  const RootGrid = styled(Grid)(() => ({
    alignContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 3, 0, 3),
    height: '100%',
  }));

  const ContentGrid = styled(Grid)(() => ({
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(2, 0),
    '&::-webkit-scrollbar': { display: 'none' },
  }));

  const componentsTabPagesPaletteTabDarkUnselected = get(
    ['components', 'tabPages', 'palette', 'tab', 'dark', 'unselected'],
    theme
  );
  const componentsTabPagesPaletteTabDarkSelected = get(
    ['components', 'tabPages', 'palette', 'tab', 'dark', 'selected'],
    theme
  );

  const componentsTabPagesTypographyTabSelected = get(
    ['components', 'tabPages', 'typography', 'tab', 'selected'],
    theme
  );
  const componentsTabPagesTypographyTabUnselected = get(
    ['components', 'tabPages', 'typography', 'tab', 'unselected'],
    theme
  );

  return (
    <RootGrid>
      {data.length > 1 && (
        <Box>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              backgroundColor: get('backgroundColor', componentsTabPagesPaletteTabDarkUnselected), // black 100
              borderRadius: '20px',
              display: 'inline-grid', // Dev Note: This is important. It keeps the background around the tab limited to the tab content.
              height: '35px',
              marginBottom: 3,
              minHeight: '35px',
              color: get('fontColor', componentsTabPagesPaletteTabDarkUnselected),
              fontFamily: get('fontFamily', componentsTabPagesTypographyTabUnselected),
              fontSize: get('fontSize', componentsTabPagesTypographyTabUnselected),
            }}
            TabIndicatorProps={{ style: { display: 'none' } }}
          >
            {data.map((tab) => (
              <Tab
                style={{ textTransform: 'none' }}
                key={tab.tabLabel}
                label={tab.tabLabel}
                // FIXME: HB-1343 switch to theme or styles
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: get(
                      'backgroundColor',
                      componentsTabPagesPaletteTabDarkSelected
                    ), // purple 30
                    color: get('fontColor', componentsTabPagesPaletteTabDarkSelected),
                  },
                  height: '35px',
                  minHeight: '35px',
                  color: '#D7BDFF', // purple 40
                  borderRadius: '20px',
                  fontFamily: get('fontFamily', componentsTabPagesTypographyTabSelected),
                  fontSize: get('fontSize', componentsTabPagesTypographyTabSelected),
                }}
              />
            ))}
          </Tabs>
        </Box>
      )}
      <ContentGrid container>{tabContent()}</ContentGrid>
    </RootGrid>
  );
};

TabbedPages.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      tabContent: PropTypes.shape({}),
      tabLabel: PropTypes.string,
    })
  ),
};
export default TabbedPages;
