import React from 'react';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';

import Button, { BUTTON_VARIANT } from '../../../components/Button';
import { TIMEKEEPING_STATUSES } from '../constants';

import { isShiftStarted } from './helperFunctions';

const showTimecardEditButton = (onClick, shiftStart, status, userRole) => {
  const isAdmin = userRole === UserRole.ADMIN;
  const isEmployer = userRole === UserRole.EMPLOYER;

  const EditButton = () => (
    <Button
      onClick={() => onClick()}
      variant={BUTTON_VARIANT.OUTLINED}
      text={LanguageConverter('button.edit')}
      sx={{ marginLeft: '24px', width: '93px' }}
      dataTestId="timecard-edit-btn"
    />
  );

  if (
    isEmployer &&
    isShiftStarted(shiftStart) &&
    (status === TIMEKEEPING_STATUSES.PENDING ||
      status === TIMEKEEPING_STATUSES.APPROVED ||
      status === TIMEKEEPING_STATUSES.DISPUTED)
  ) {
    return <EditButton />;
  }
  if (
    isAdmin &&
    isShiftStarted(shiftStart) &&
    (status === TIMEKEEPING_STATUSES.PENDING ||
      status === TIMEKEEPING_STATUSES.APPROVED ||
      status === TIMEKEEPING_STATUSES.RESOLVED ||
      status === TIMEKEEPING_STATUSES.DISPUTED)
  ) {
    return <EditButton />;
  }
  return null;
};

export default showTimecardEditButton;
