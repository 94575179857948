import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import selectUser from '../../../store/selectors/appSelector';
import { BLACK, FEED_BACK } from '../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../theme/fontConstants';
import { epochToDateInReadableFormat, epochToTimeInReadableFormat } from '../../../utils';
import { ROUTE_CONSTANTS } from '../../../views/app/routeConstants';
import { JOB_ORDER_TIME_STATUSES } from '../../../views/jobOrders/jobOrderConstants';

import ActiveAndUpcomingJobMetrics from './subComponents/ActiveAndUpcomingJobMetrics';
import CancelledJobMetrics from './subComponents/CancelledJobMetrics';
import CompletedJobMetrics from './subComponents/CompletedJobMetrics';

const useStyles = makeStyles({
  content: {
    fontFamily: PRIMARY_FONT[300],
  },
  titleBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .status': {
      display: 'flex',
      flexDirection: 'row',
      gap: '12px',
      alignItems: 'center',
      '&.activeStatus': {
        color: FEED_BACK.GREEN_70,
        width: '17px',
        height: '17px',
      },
      '&.date': {
        fontSize: '16px',
        color: BLACK[70],
        fontWeight: '300',
        lineHeight: '12px',
      },
    },
    '& .title': {
      width: '100%',
      display: 'block',
      textAlign: 'left',
      fontSize: '20px',
      fontWeight: '700',
      lineHeight: '25px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      paddingTop: '4px',
    },
  },
  divider: {
    borderBottom: '0.5px solid #00000040',
    margin: '6px 0',
  },
  jobInfo: {
    display: 'flex',
    flexDirection: 'column',
    padding: '6px 0',
    gap: '12px',
    '& .block': {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      '& .title': {
        color: 'gray',
        textTransform: 'uppercase',
        fontSize: '12px',
        lineHeight: '8px',
        '& .twoColumn': {
          display: 'flex',
          flexDirection: 'row',
          gap: '12px',
        },
      },
      '& .data': {
        fontWeight: 300,
        fontSize: '16px',
        lineHeight: '16px',
        textAlign: 'left',
        '& .twoColumn': {
          display: 'flex',
          flexDirection: 'row',
          gap: '14px',
          '&.times': {
            gap: '8px',
          },
          '&.staffing': {
            gap: '76px',
          },
        },
      },
    },
  },
  corpNameText: {
    maxWidth: '180px',
    display: 'block',
    textAlign: 'left',
    fontSize: '14px',
    fontFamily: PRIMARY_FONT[300],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingRight: '3px',
  },
  exIdText: {
    display: 'block',
    textAlign: 'left',
    fontSize: '14px',
    fontFamily: PRIMARY_FONT[300],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  detailButton: {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    borderRadius: '5px',
    color: '#7A23FF',
    border: '1px solid #7A23FF',
    height: '25px',
    padding: '4px 12px 4px 12px',
    gap: '4px',
    opacity: '0px',
  },
});

const JobView = ({ jobOrder, timeStatus, isCancelled }) => {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div>
      <div className={classes.content}>
        <div className={classes.titleBlock}>
          <div className="status">
            <div className="status date">{epochToDateInReadableFormat(jobOrder.start)}</div>
          </div>
          <Typography className="status title"> {jobOrder.name} </Typography>
          {user.role === UserRole.ADMIN && (
            <Box sx={{ display: 'flex' }}>
              <Typography className={classes.corpNameText}>
                {jobOrder?.corporation?.name}
              </Typography>
              <Typography className={classes.exIdText}>
                {`• EXID ${jobOrder?.metadata?.bullhorn?.id}`}
              </Typography>
            </Box>
          )}
        </div>
        <div className={classes.divider} />
        <div className={classes.jobInfo}>
          <div className="block">
            <div className="title"> {LanguageConverter('jobOrder.aShift')}</div>
            <div className="data">
              <div className="twoColumn times">
                <div> {epochToTimeInReadableFormat(jobOrder.start)} </div>
                <div> - </div>
                <div> {epochToTimeInReadableFormat(jobOrder.end)} </div>
              </div>
            </div>
          </div>
          <div className="block">
            <div className="title">
              <div className="twoColumn">
                <div> {LanguageConverter('jobOrder.startDate')} </div>
                <div> {LanguageConverter('jobOrder.endDate')} </div>
              </div>
            </div>
            <div className="data">
              <div className="twoColumn">
                <div> {epochToDateInReadableFormat(jobOrder.start)} </div>
                <div> - </div>
                <div> {epochToDateInReadableFormat(jobOrder.end)} </div>
              </div>
            </div>
          </div>
          <div className="block">
            <div className="title"> {LanguageConverter('jobOrder.location')} </div>
            <div className="data">
              {jobOrder?.location
                ? `${jobOrder.location?.address}, ${jobOrder.location?.city}, ${jobOrder.location?.state}, ${jobOrder.location?.zipcode}`
                : ''}
            </div>
          </div>
          {(isCancelled && <CancelledJobMetrics jobOrder={jobOrder} />) ||
            (timeStatus === JOB_ORDER_TIME_STATUSES.COMPLETED && (
              <CompletedJobMetrics jobOrder={jobOrder} />
            )) || <ActiveAndUpcomingJobMetrics jobOrder={jobOrder} />}
          <Box sx={{ display: 'flex' }}>
            <button
              type="button"
              className={classes.detailButton}
              onClick={() => {
                const jobIdPath = generatePath(`/${ROUTE_CONSTANTS.JOB_ORDERS}/:id`, {
                  id: jobOrder?._id,
                });
                navigate(`${jobIdPath}${location.search}`);
              }}
            >
              {timeStatus === JOB_ORDER_TIME_STATUSES.COMPLETED
                ? LanguageConverter('jobOrder.postShiftReportDetails')
                : LanguageConverter('jobOrder.manageJobAndRoster')}
            </button>
          </Box>
        </div>
      </div>
    </div>
  );
};

JobView.propTypes = {
  jobOrder: PropTypes.shape({
    extensionGroup: PropTypes.string,
    corporation: PropTypes.shape({
      name: PropTypes.string,
    }),
    _id: PropTypes.string,
    name: PropTypes.string,
    start: PropTypes.number,
    end: PropTypes.number,
    fillRatio: PropTypes.number,
    numOpenings: PropTypes.number,
    placementsCount: PropTypes.number,
    placements: PropTypes.arrayOf(PropTypes.shape({})),
    location: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipcode: PropTypes.string,
    }),
    metadata: PropTypes.shape({
      bullhorn: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
  }),
  isCancelled: PropTypes.bool.isRequired,
  timeStatus: PropTypes.string.isRequired,
};

export default JobView;
