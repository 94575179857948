import React, { useCallback, useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Field, Form as RFForm } from 'react-final-form';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Button, { BUTTON_TYPE } from '../../../../components/Button';
import { buildFormField } from '../../../../components/Form/formFieldFactory';
import TimePickerField from '../../../../components/TimePicker/TimePickerField';
import theme from '../../../../theme';
import { addHours, formatCurrency, timeToMinutesFromMidnight } from '../../../../utils';

import AdminFormFieldDataShiftDetails from './FormData/AdminFormFieldDataShiftDetails';

const AdminShiftDetail = ({ initialValues, onUpdate, certifications }) => {
  const [shifts, setShifts] = useState(initialValues?.shifts || []);
  const formFieldData = AdminFormFieldDataShiftDetails(certifications, initialValues);

  const defaultShiftStartTime = new Date(new Date().setHours(0, 0, 0, 0));
  const defaultShiftEndTime = addHours(8, defaultShiftStartTime);
  const isShiftDeleted = initialValues?.shifts?.length > shifts?.length;

  const addNewShift = () => {
    const newShift = {
      name: '',
      start: timeToMinutesFromMidnight(defaultShiftStartTime),
      end: timeToMinutesFromMidnight(defaultShiftEndTime),
    };
    setShifts([newShift, ...shifts]);
  };

  const handleRemoveShift = useCallback(
    (index) => {
      const newShifts = shifts.filter((shift, shiftIndex) => shiftIndex !== index);
      setShifts(newShifts);
    },
    [shifts]
  );

  const StyledFixedBottomBox = styled(Box)(() => ({
    position: 'absolute',
    backgroundImage: `linear-gradient(81.37deg, ${theme.button.palette.primary} 33.18%, ${theme.button.palette.secondary} 94.14%)`,
    fontFamily: `${theme.button.fontFamily}`,
    color: '#FFF',
    width: '100%',
    left: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '4.40vh',
    cursor: 'pointer',
    borderBottomLeftRadius: '13px',
    borderBottomRightRadius: '13px',
  }));

  const componentsDetailViewSectionHeaders = [
    'components',
    'detailView',
    'typography',
    'sectionHeader',
  ];

  const componentsDetailViewFieldHeaders = [
    'components',
    'detailView',
    'typography',
    'fieldHeaders',
  ];

  const componentsDetailViewSemiBoldText = [
    'components',
    'detailView',
    'typography',
    'semiBoldText',
  ];
  const viewHeaderStyling = {
    fontSize: get([...componentsDetailViewSectionHeaders, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewSectionHeaders, 'fontFamily'], theme),
    color: get([...componentsDetailViewSectionHeaders, 'fontColor'], theme),
    margin: theme.spacing(4, 0, 0, 1),
  };

  const fieldHeaderStyling = {
    fontSize: get([...componentsDetailViewFieldHeaders, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewFieldHeaders, 'fontFamily'], theme),
    color: get([...componentsDetailViewFieldHeaders, 'fontColor'], theme),
    margin: theme.spacing(2, 0, 2, 1),
  };

  const readOnlyFieldBoldValueStyling = {
    fontSize: get([...componentsDetailViewSemiBoldText, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewSemiBoldText, 'fontFamily'], theme),
    color: get([...componentsDetailViewSemiBoldText, 'fontColor'], theme),
  };

  const adjustedInitialValues = {
    ...initialValues,
    shifts,
    payRateDT: formatCurrency(get('payRateDT', initialValues)),
    payRateOT: formatCurrency(get('payRateOT', initialValues)),
    payRateReg: formatCurrency(get('payRateReg', initialValues)),
    billRateDT: formatCurrency(get('billRateDT', initialValues)),
    billRateOT: formatCurrency(get('billRateOT', initialValues)),
    billRateReg: formatCurrency(get('billRateReg', initialValues)),
  };

  const minutesToDate = (minutes) => {
    const date = new Date();
    date.setHours(Math.floor(minutes / 60));
    date.setMinutes(minutes % 60);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  };

  return (
    <RFForm
      onSubmit={(values) => {
        onUpdate(values, initialValues);
      }}
      initialValues={adjustedInitialValues}
      render={({ handleSubmit, pristine, hasValidationErrors }) => (
        <Box>
          <Box
            sx={{
              width: '100%',
              overflowY: 'auto',
              '&::-webkit-scrollbar': { display: 'none' },
              height: '80vh',
            }}
          >
            <Box display="flex" justifyContent="flex-end">
              <Button onClick={addNewShift} type={BUTTON_TYPE.BUTTON} text="Add Shift" />
            </Box>
            <form
              onSubmit={handleSubmit}
              id="loginformid"
              style={{
                width: '100%',
              }}
            >
              <Box display="flex" flexDirection="column" gap={4}>
                <Typography sx={{ ...viewHeaderStyling, margin: theme.spacing(1, 0, 0, 0) }}>
                  Shifts
                </Typography>
                <Box display="flex" flexDirection="column" gap={8}>
                  {shifts.map((shift, index) => (
                    <Box display="flex" flexDirection="column" gap={4} key={shift.name}>
                      <Box display="flex" width="50%">
                        {buildFormField(formFieldData.assignedShiftName(index))}
                        <IconButton color="error" onClick={() => handleRemoveShift(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                      <Box display="flex" flexDirection="row" gap="12px">
                        <Field name={`shifts[${index}].start`} initialValue={shift.start}>
                          {({ input }) => (
                            <TimePickerField
                              {...input}
                              label="Start Time"
                              value={minutesToDate(input.value)}
                              onChange={(value) => {
                                const minutes = value ? timeToMinutesFromMidnight(value) : value;
                                input.onChange(minutes);
                              }}
                              mode="dark"
                              required
                              error={
                                Number.isNaN(Date.parse(input.value))
                                  ? 'Incorrect time format'
                                  : undefined
                              }
                              sx={{ width: '100%' }}
                            />
                          )}
                        </Field>
                        <Field name={`shifts[${index}].end`} initialValue={shift.end}>
                          {({ input }) => (
                            <TimePickerField
                              {...input}
                              label="End Time"
                              value={minutesToDate(input.value)}
                              onChange={(value) => {
                                const minutes = value
                                  ? timeToMinutesFromMidnight(value)
                                  : defaultShiftEndTime;
                                input.onChange(minutes);
                              }}
                              mode="dark"
                              required
                              error={
                                Number.isNaN(Date.parse(input.value))
                                  ? 'Incorrect time format'
                                  : undefined
                              }
                              sx={{ width: '100%' }}
                            />
                          )}
                        </Field>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Grid container spacing={1} direction="column" sx={{ width: '100%' }}>
                <Typography sx={viewHeaderStyling}>Bill Rate</Typography>
                <Grid container item spacing={1} sx={{ margin: theme.spacing(1.5, 0, 0, 0) }}>
                  <Grid item xs={6}>
                    {buildFormField(formFieldData.billRateReg)}
                  </Grid>
                  <Grid item xs={6}>
                    {buildFormField(formFieldData.billRateDT)}
                  </Grid>
                  <Grid item xs={6} sx={{ margin: theme.spacing(3, 0, 0, 0) }}>
                    {buildFormField(formFieldData.billRateOT)}
                  </Grid>
                </Grid>
                <Typography sx={viewHeaderStyling}>Pay Rate</Typography>
                <Grid container item spacing={1} sx={{ margin: theme.spacing(1.5, 0, 0, 0) }}>
                  <Grid item xs={6}>
                    {buildFormField(formFieldData.payRateReg)}
                  </Grid>
                  <Grid item xs={6}>
                    {buildFormField(formFieldData.payRateDT)}
                  </Grid>
                  <Grid item xs={6} sx={{ margin: theme.spacing(3, 0, 0, 0) }}>
                    {buildFormField(formFieldData.payRateOT)}
                  </Grid>
                </Grid>
                <Typography sx={viewHeaderStyling}>Overstaffing Percentage</Typography>
                <Grid container item spacing={1} sx={{ margin: theme.spacing(1.5, 0, 0, 0) }}>
                  <Grid item xs={6}>
                    {buildFormField(formFieldData.overstaffingPercent)}
                  </Grid>
                </Grid>
                <Typography sx={viewHeaderStyling}>Workers Comp</Typography>
                <Grid container item spacing={1} sx={{ margin: theme.spacing(1.5, 0, 0, 0) }}>
                  <Grid item xs={6}>
                    {buildFormField(formFieldData.workersCompCode)}
                  </Grid>
                </Grid>
                <Typography sx={viewHeaderStyling}>GPS</Typography>
                <Grid container item spacing={1} sx={{ margin: theme.spacing(0, 0, 0, 0) }}>
                  <Grid item xs={6}>
                    <Typography sx={{ ...fieldHeaderStyling, margin: theme.spacing(0, 0, 1.5, 0) }}>
                      GPS Strategy
                    </Typography>
                    <Typography sx={readOnlyFieldBoldValueStyling}>
                      {get(['gps', 'strategy'], initialValues)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ ...fieldHeaderStyling, margin: theme.spacing(0, 0, 1.5, 0) }}>
                      Max Punch Distance
                    </Typography>
                    <Typography sx={readOnlyFieldBoldValueStyling}>
                      {get(['gps', 'maxDistance'], initialValues)}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography sx={{ ...viewHeaderStyling, margin: theme.spacing(4, 0, 0, 1) }}>
                  Required Certifications
                </Typography>
                <Grid item xs={12} sx={{ margin: theme.spacing(0, 0, 3, 0) }}>
                  {buildFormField(formFieldData.certs)}
                </Grid>
                <Typography sx={{ ...viewHeaderStyling, margin: theme.spacing(4, 0, 0, 1) }}>
                  Required Skills
                </Typography>
                <Grid item xs={12} sx={{ margin: theme.spacing(0, 0, 3, 0) }}>
                  {buildFormField(formFieldData.skills)}
                </Grid>
              </Grid>
              {(!pristine && !hasValidationErrors) || isShiftDeleted ? (
                <StyledFixedBottomBox>
                  <Button
                    sx={{ width: '100%' }}
                    type={BUTTON_TYPE.SUBMIT}
                    text={LanguageConverter('buttonText.submit')}
                    disabled={hasValidationErrors}
                  />
                </StyledFixedBottomBox>
              ) : null}
            </form>
          </Box>
        </Box>
      )}
    />
  );
};

AdminShiftDetail.propTypes = {
  certifications: PropTypes.arrayOf(PropTypes.shape({})),
  initialValues: PropTypes.shape({
    shifts: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onUpdate: PropTypes.func,
};

export default AdminShiftDetail;
