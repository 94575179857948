import * as React from 'react';
import { dropRight, get, last, omit } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';

import StarOne from '../../assets/Success.svg';
import theme from '../../theme';
import Button, { BUTTON_TYPE, BUTTON_VARIANT } from '../Button';
import Form from '../Form';
import IconButton from '../IconButton';

const WorkFlow = ({
  background,
  confirmationPageButtonText,
  formSpec = [[]],
  attemptToHandleAPICall,
  onClose,
  onSubmit,
  readOnlyValues = {},
  isApiCallProcessing,
  processingMessage,
  errorMessage,
  successMessage,
  workFlowValuesUpdatedCallBack,
  validateForm,
}) => {
  const intl = useIntl();

  const [activeStep, setActiveStep] = React.useState(0);
  const [workflowValues, setWorkflowValues] = React.useState({});
  const [workflowStageValues, setWorkflowStageValues] = React.useState([]);
  let workflowTransitionPageBgColor;
  let workflowConfirmationPageBgColor;
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    if (activeStep === 0) {
      onClose();
      return;
    }

    const lastValue = last(workflowStageValues);

    const omittedWorkFlowValues = omit(Object.keys(lastValue), workflowValues);
    setWorkflowValues(omittedWorkFlowValues);
    workFlowValuesUpdatedCallBack && workFlowValuesUpdatedCallBack(omittedWorkFlowValues);
    setWorkflowStageValues(dropRight(1, workflowStageValues));
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const WORKFLOW_BACKGROUND = {
    DARK: 'dark',
    DEFAULT: 'default',
    LIGHT: 'light',
  };
  const componentWorkFlowFont = ['components', 'workflow', 'typography'];
  const componentWorkFlowPaletteBackgroundDark = [
    'components',
    'workflow',
    'palette',
    'background',
    'dark',
  ];
  const componentWorkFlowPaletteBackgroundDefault = [
    'components',
    'workflow',
    'palette',
    'background',
    'default',
  ];
  const componentWorkFlowPaletteFontDefault = [
    'components',
    'workflow',
    'palette',
    'font',
    'default',
  ];

  switch (background) {
    case WORKFLOW_BACKGROUND.DARK:
      workflowTransitionPageBgColor = get(
        [...componentWorkFlowPaletteBackgroundDark, 'transitionPageBackgroundColor'],
        theme
      );
      workflowConfirmationPageBgColor = get(
        [...componentWorkFlowPaletteBackgroundDark, 'confirmationPageBackgroundColor'],
        theme
      );

      break;
    case WORKFLOW_BACKGROUND.LIGHT:
      workflowTransitionPageBgColor = get(
        [...componentWorkFlowPaletteBackgroundDefault, 'transitionPageBackgroundColor'],
        theme
      );
      workflowConfirmationPageBgColor = get(
        [...componentWorkFlowPaletteBackgroundDefault, 'confirmationPageBackgroundColor'],
        theme
      );

      break;
    default:
      workflowTransitionPageBgColor = get(
        [...componentWorkFlowPaletteBackgroundDefault, 'transitionPageBackgroundColor'],
        theme
      );
      workflowConfirmationPageBgColor = get(
        [...componentWorkFlowPaletteBackgroundDefault, 'confirmationPageBackgroundColor'],
        theme
      );
  }

  const backButton = {
    color: 'primary',
    onClick: handleBack,
    startIcon: <ArrowBackIcon sx={{ color: get(['button', 'palette', 'secondary'], theme) }} />,
    text: LanguageConverter('buttonText.goBack'),
    variant: BUTTON_VARIANT.OUTLINED,
  };
  const continueButton = {
    color: 'primary',
    endIcon: <ArrowForwardIcon sx={{ color: get(['button', 'palette', 'whiteIcon'], theme) }} />,
    text: LanguageConverter('buttonText.continue'),
    type: BUTTON_TYPE.SUBMIT,
  };

  const intermediateFormOnSubmits = (formValues) => {
    const flowValues = { ...workflowValues, ...formValues };
    setWorkflowValues(flowValues);
    setWorkflowStageValues([...workflowStageValues, formValues]);
    workFlowValuesUpdatedCallBack && workFlowValuesUpdatedCallBack(flowValues);
    handleNext();
  };

  const calculatedWorkFlowData = formSpec.map((formData, index) => {
    const createButtons = (formDataIndex) => [
      (formDataIndex === 0 && {
        ...backButton,
      }) ||
        backButton,
      (formDataIndex === formSpec.length - 1 && {
        color: 'primary',
        text: LanguageConverter('buttonText.submit'),
        type: BUTTON_TYPE.SUBMIT,
        sx: {
          width: '100%',
        },
      }) ||
        continueButton,
    ];

    const fullWorkFlowFormSubmit = (x) => {
      const finalValues = { ...workflowValues, ...x };

      onSubmit && onSubmit(finalValues);

      const apiCallWasHandled = attemptToHandleAPICall(finalValues);

      if (apiCallWasHandled) {
        handleNext();
      }
    };

    return {
      buttonData: createButtons(index),
      formFieldData: formData,
      onSubmit:
        (index === formSpec.length - 1 && fullWorkFlowFormSubmit) || intermediateFormOnSubmits,
      stepKey: `Step ${index}`,
    };
  });

  const buildWorkFlowPage = (pageData, initialValues) => (
    <WorkFlowBox container item>
      <Form
        // FIXME: Form (as with all components) should style themselves. Remove this.
        // If we need to add a property to set the "mode" of the form, we can do that.
        actionButtonGridBackgroundColor={get(['palette', 'background', 'default'], theme)}
        key={get('stepKey', pageData)}
        buttonData={get('buttonData', pageData)}
        formFieldData={get('formFieldData', pageData)}
        formId={get('stepKey', pageData)}
        initialValues={{ ...readOnlyValues, ...initialValues }}
        onSubmit={get('onSubmit', pageData)}
        setButtonOnBottomAndFixed
        validateForm={validateForm}
      />
    </WorkFlowBox>
  );

  const RootBox = styled(Box)(() => ({
    height: '100vh',
    position: 'relative',
    justifyContent: 'flex-start',
    width: '100%',
  }));
  const WorkFlowBox = styled(Box)(() => ({
    width: '100%',
    whiteSpace: 'pre-wrap',
    padding: theme.spacing(2, 8),
  }));

  const ConfirmationBodyBox = styled(Box)(() => ({
    height: '30%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  }));

  const BodyBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(16, 8, 5),
    height: '100%',
    minWidth: '100%',
    backgroundColor:
      activeStep === calculatedWorkFlowData.length
        ? workflowConfirmationPageBgColor
        : workflowTransitionPageBgColor,
  }));

  const FinalPageTitleTypography = styled(Box)(() => ({
    fontSize: get([...componentWorkFlowFont, 'defaultTitleFontSize'], theme),
    color: get([...componentWorkFlowPaletteFontDefault, 'defaultLastPageFontColor'], theme),

    fontFamily: get([...componentWorkFlowFont, 'boldFont'], theme),
  }));
  // FIXME: Button needs to change to style based on HB-1345

  const HeaderBox = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'end',
    padding: theme.spacing(2),
  }));
  const ConfirmationButtonSx = {
    background: 'white',
    color: `#0073E5`,
    width: 'fit-content',
    '&:hover, &.Mui-hovered': {
      background: `${theme.button.palette.primary}`,
      boxShadow: 'none',
      border: `2px solid ${theme.button.palette.primary}`,
      color: 'white',
      WebkitBackgroundClip: 'border-box',
      WebkitTextFillColor: 'white',
    },
  };

  return (
    <RootBox>
      {activeStep < calculatedWorkFlowData.length && (
        <HeaderBox>
          <IconButton
            iconElement={<CloseIcon />}
            onClick={() => onClose()}
            size="small"
            sx={{ marginLeft: 0.5, marginTop: 0.5 }}
          />
        </HeaderBox>
      )}
      {calculatedWorkFlowData.length > 1 && activeStep !== calculatedWorkFlowData.length && (
        <Grid item xs={12} maxHeight="10%">
          <Stepper activeStep={activeStep} alternativeLabel>
            {calculatedWorkFlowData.map((workFlowStep) => (
              <Step key={`label-${workFlowStep.stepKey}`}>
                <StepLabel> </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      )}

      {(activeStep === calculatedWorkFlowData.length && (isApiCallProcessing || errorMessage) && (
        <BodyBox>
          <ConfirmationBodyBox>
            <FinalPageTitleTypography>
              {intl.formatMessage({ id: isApiCallProcessing ? processingMessage : errorMessage })}
            </FinalPageTitleTypography>
            <Button
              sx={ConfirmationButtonSx}
              onClick={() => onClose()}
              disabled={isApiCallProcessing}
              text={confirmationPageButtonText}
            />
          </ConfirmationBodyBox>
        </BodyBox>
      )) ||
        (calculatedWorkFlowData.length !== 0 &&
          activeStep === calculatedWorkFlowData.length &&
          !errorMessage && (
            <BodyBox>
              <Box
                component="img"
                sx={{
                  height: 51.52,
                  width: 51.52,
                  marginBottom: 2,
                }}
                alt="Stars"
                src={StarOne}
              />
              <ConfirmationBodyBox>
                <FinalPageTitleTypography>
                  {intl.formatMessage({ id: successMessage })}
                </FinalPageTitleTypography>

                <Button
                  sx={ConfirmationButtonSx}
                  onClick={() => onClose()}
                  text={confirmationPageButtonText}
                />
              </ConfirmationBodyBox>
            </BodyBox>
          )) ||
        (activeStep < calculatedWorkFlowData.length &&
          buildWorkFlowPage(calculatedWorkFlowData[activeStep], workflowValues))}
    </RootBox>
  );
};

WorkFlow.propTypes = {
  background: PropTypes.string,
  confirmationPageButtonText: PropTypes.string,
  formSpec: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        ComponentType: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
        label: PropTypes.string,
        name: PropTypes.string,
      })
    )
  ),
  attemptToHandleAPICall: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitApiCallData: PropTypes.shape({
    handleSubmit: PropTypes.func,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
  }),
  readOnlyValues: PropTypes.shape({}),
  isApiCallProcessing: PropTypes.bool,
  processingMessage: PropTypes.string,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  workFlowValuesUpdatedCallBack: PropTypes.func,
  validateForm: PropTypes.func,
};

export default WorkFlow;
