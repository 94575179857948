import React from 'react';
import { useFormState } from 'react-final-form';

import Grid from '@mui/material/Grid';

import { buildFormField } from '../../../../components/Form/formFieldFactory';
import theme from '../../../../theme';

import {
  allowBackfillField,
  allowOvertimeField,
  isGPSEnforcedField,
  punchDistanceField,
  requireEmployerApprovalField,
} from './FormData/AdminFormFieldDataAdditionalData';

const AdminAdditionalDetailsFormBody = () => {
  const formState = useFormState();
  const currentFormValues = formState?.values;

  return (
    <Grid
      container
      spacing={1}
      direction="column"
      sx={{ width: '100%', color: '#FFF', padding: theme.spacing(2) }}
    >
      <Grid item xs={12} sx={{ margin: theme.spacing(0, 0, 3, 0) }}>
        {buildFormField(requireEmployerApprovalField)}
      </Grid>
      <Grid item xs={12} sx={{ margin: theme.spacing(0, 0, 3, 0) }}>
        {buildFormField(allowBackfillField)}
      </Grid>
      <Grid item xs={12} sx={{ margin: theme.spacing(0, 0, 3, 0) }}>
        {buildFormField(allowOvertimeField)}
      </Grid>
      <Grid item xs={12} sx={{ margin: theme.spacing(0, 0, 3, 0) }}>
        {buildFormField(isGPSEnforcedField)}
      </Grid>
      {currentFormValues.gpsEnforced && (
        <Grid item xs={12} sx={{ margin: theme.spacing(0, 0, 3, 0) }}>
          {buildFormField(punchDistanceField)}
        </Grid>
      )}
    </Grid>
  );
};

AdminAdditionalDetailsFormBody.propTypes = {};

export default AdminAdditionalDetailsFormBody;
