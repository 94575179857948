import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const SmsIcon = () => (
  <SvgIcon>
    <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5085_3793)">
        <path
          d="M25.0065 2.3335H5.43627C4.09082 2.3335 2.98999 3.4135 2.98999 4.7335V26.3335L7.88255 21.5335H25.0065C26.352 21.5335 27.4528 20.4535 27.4528 19.1335V4.7335C27.4528 3.4135 26.352 2.3335 25.0065 2.3335ZM25.0065 19.1335H7.88255L5.43627 21.5335V4.7335H25.0065V19.1335Z"
          fill="black"
        />
        <path
          d="M18.0938 11.8617C18.0938 11.5028 18.2145 11.2037 18.4561 10.9645C18.6976 10.7203 19.0088 10.5981 19.3898 10.5981C19.7753 10.5981 20.0865 10.7203 20.3234 10.9645C20.565 11.2037 20.6858 11.5028 20.6858 11.8617C20.6858 12.2205 20.565 12.5221 20.3234 12.7663C20.0865 13.0055 19.7753 13.1252 19.3898 13.1252C19.0088 13.1252 18.6976 13.0055 18.4561 12.7663C18.2145 12.5221 18.0938 12.2205 18.0938 11.8617Z"
          fill="black"
        />
        <path
          d="M13.7737 11.8617C13.7737 11.5028 13.8945 11.2037 14.136 10.9645C14.3776 10.7203 14.6888 10.5981 15.0697 10.5981C15.4552 10.5981 15.7665 10.7203 16.0034 10.9645C16.2449 11.2037 16.3657 11.5028 16.3657 11.8617C16.3657 12.2205 16.2449 12.5221 16.0034 12.7663C15.7665 13.0055 15.4552 13.1252 15.0697 13.1252C14.6888 13.1252 14.3776 13.0055 14.136 12.7663C13.8945 12.5221 13.7737 12.2205 13.7737 11.8617Z"
          fill="black"
        />
        <path
          d="M9.45361 11.8617C9.45361 11.5028 9.57439 11.2037 9.81594 10.9645C10.0575 10.7203 10.3687 10.5981 10.7496 10.5981C11.1352 10.5981 11.4464 10.7203 11.6833 10.9645C11.9248 11.2037 12.0456 11.5028 12.0456 11.8617C12.0456 12.2205 11.9248 12.5221 11.6833 12.7663C11.4464 13.0055 11.1352 13.1252 10.7496 13.1252C10.3687 13.1252 10.0575 13.0055 9.81594 12.7663C9.57439 12.5221 9.45361 12.2205 9.45361 11.8617Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_5085_3793">
          <rect width="28.5399" height="28" fill="white" transform="translate(0.611572)" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default SmsIcon;
