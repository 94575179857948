import * as React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '@mui/material/SvgIcon';

const TimekeepingIcon = ({ width = 23, height = 23, color = '#67676A' }) => (
  <SvgIcon sx={{ width, height, color }}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.667 2.36364V10.3087H20.6121C20.1509 6.1371 16.8386 2.82479 12.667 2.36364ZM22.667 11.3087C22.667 5.23358 17.7421 0.308716 11.667 0.308716C5.59186 0.308716 0.666992 5.23358 0.666992 11.3087C0.666992 17.3838 5.59186 22.3087 11.667 22.3087C17.7421 22.3087 22.667 17.3838 22.667 11.3087ZM20.6121 12.3087H13.4712L17.2556 18.3638C19.0883 16.9101 20.3414 14.7574 20.6121 12.3087ZM15.5606 19.4252L10.819 11.8387C10.7197 11.6798 10.667 11.4961 10.667 11.3087V2.36364C6.16704 2.86109 2.66699 6.67616 2.66699 11.3087C2.66699 16.2793 6.69643 20.3087 11.667 20.3087C13.0617 20.3087 14.3823 19.9915 15.5606 19.4252Z"
        fill="#4D4D70"
      />
    </svg>
  </SvgIcon>
);

TimekeepingIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default TimekeepingIcon;
