import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const MarkChatUnreadIcon = () => (
  <SvgIcon>
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_230_7049)">
        <path
          d="M23.9334 2.33325H4.73337C3.41337 2.33325 2.33337 3.41325 2.33337 4.73325V26.3333L7.13337 21.5333H23.9334C25.2534 21.5333 26.3334 20.4533 26.3334 19.1333V4.73325C26.3334 3.41325 25.2534 2.33325 23.9334 2.33325ZM23.9334 19.1333H7.13337L4.73337 21.5333V4.73325H23.9334V19.1333Z"
          fill="black"
        />
        <path
          d="M17.1514 11.8614C17.1514 11.5025 17.2699 11.2035 17.5068 10.9642C17.7438 10.72 18.0492 10.5979 18.4229 10.5979C18.8011 10.5979 19.1064 10.72 19.3389 10.9642C19.5758 11.2035 19.6943 11.5025 19.6943 11.8614C19.6943 12.2203 19.5758 12.5218 19.3389 12.7661C19.1064 13.0053 18.8011 13.1249 18.4229 13.1249C18.0492 13.1249 17.7438 13.0053 17.5068 12.7661C17.2699 12.5218 17.1514 12.2203 17.1514 11.8614Z"
          fill="black"
        />
        <path
          d="M12.9131 11.8614C12.9131 11.5025 13.0316 11.2035 13.2686 10.9642C13.5055 10.72 13.8109 10.5979 14.1846 10.5979C14.5628 10.5979 14.8682 10.72 15.1006 10.9642C15.3376 11.2035 15.4561 11.5025 15.4561 11.8614C15.4561 12.2203 15.3376 12.5218 15.1006 12.7661C14.8682 13.0053 14.5628 13.1249 14.1846 13.1249C13.8109 13.1249 13.5055 13.0053 13.2686 12.7661C13.0316 12.5218 12.9131 12.2203 12.9131 11.8614Z"
          fill="black"
        />
        <path
          d="M8.6748 11.8614C8.6748 11.5025 8.79329 11.2035 9.03027 10.9642C9.26725 10.72 9.57259 10.5979 9.94629 10.5979C10.3245 10.5979 10.6299 10.72 10.8623 10.9642C11.0993 11.2035 11.2178 11.5025 11.2178 11.8614C11.2178 12.2203 11.0993 12.5218 10.8623 12.7661C10.6299 13.0053 10.3245 13.1249 9.94629 13.1249C9.57259 13.1249 9.26725 13.0053 9.03027 12.7661C8.79329 12.5218 8.6748 12.2203 8.6748 11.8614Z"
          fill="black"
        />
        <circle cx="23.5" cy="4.5" r="3.75" fill="#B23E3E" stroke="white" strokeWidth="1.5" />
      </g>
      <defs>
        <clipPath id="clip0_230_7049">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default MarkChatUnreadIcon;
