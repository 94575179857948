import React, { useCallback } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Form as RFForm } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { ROUTE_CONSTANTS } from '../../../../../main/views/app/routeConstants';
import Button, { BUTTON_TYPE, BUTTON_VARIANT } from '../../../../components/Button';
import { buildFormField } from '../../../../components/Form/formFieldFactory';
import IconButton from '../../../../components/IconButton';
import theme from '../../../../theme';

import AdminFormFieldDataOverview from './FormData/AdminFormFieldDataOverview';

const AdminOverview = ({
  availableLocations = [], // TODO: Remove available locations once searchbar supports location API filtering!
  initialValues,
  onDelete,
  onUpdate,
}) => {
  const intl = useIntl();
  const corpId = get(['corporation', '_id'], initialValues);
  const formFieldData = AdminFormFieldDataOverview(availableLocations, corpId);

  const buttonHeight = '4.40vh';
  const buttonSx = {
    position: 'absolute',
    backgroundImage: `linear-gradient(81.37deg, ${theme.button.palette.primary} 33.18%, ${theme.button.palette.secondary} 94.14%)`,
    fontFamily: `${theme.button.fontFamily}`,
    color: '#FFF',
    width: '100%',
    left: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: buttonHeight,
    cursor: 'pointer',
    borderBottomLeftRadius: '13px',
    borderBottomRightRadius: '13px',
  };

  const componentsDetailViewSectionHeaders = [
    'components',
    'detailView',
    'typography',
    'sectionHeader',
  ];
  const componentsDetailViewFieldHeaders = [
    'components',
    'detailView',
    'typography',
    'fieldHeaders',
  ];
  const componentsDetailViewSemiBoldText = [
    'components',
    'detailView',
    'typography',
    'semiBoldText',
  ];
  const viewHeaderStyling = {
    fontSize: get([...componentsDetailViewSectionHeaders, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewSectionHeaders, 'fontFamily'], theme),
    color: get([...componentsDetailViewSectionHeaders, 'fontColor'], theme),
  };
  const fieldHeaderStyling = {
    fontSize: get([...componentsDetailViewFieldHeaders, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewFieldHeaders, 'fontFamily'], theme),
    color: get([...componentsDetailViewFieldHeaders, 'fontColor'], theme),
    margin: theme.spacing(2, 0, 2, 1),
  };
  const readOnlyFieldValueStyling = {
    fontSize: get([...componentsDetailViewSemiBoldText, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewSemiBoldText, 'fontFamily'], theme),
    color: get([...componentsDetailViewSemiBoldText, 'fontColor'], theme),
    margin: theme.spacing(0, 0, 0, 1),
  };
  const handleOnSubmit = useCallback(
    (values) => {
      const submitValues = {
        ...values,
        branch: get(['branch', 'value'], values),
        legalBusEntry: get(['legalBusEntry', 'value'], values),
        benefitsPack: get(['benefitsPack', 'value'], values),
        payGroup: get(['payGroup', 'value'], values),
      };
      onUpdate(submitValues, initialValues);
    },
    [initialValues, onUpdate]
  );

  const initialBranch = get(['branch'], initialValues);
  const branchValue = {
    id: initialBranch,
    name: initialBranch,
    value: initialBranch,
  };

  const initialLegalBusEntry = get(['legalBusEntry'], initialValues);
  const legalBusEntryValue = {
    id: initialLegalBusEntry,
    name: initialLegalBusEntry,
    value: initialLegalBusEntry,
  };

  const initialBenefitPack = get(['benefitsPack'], initialValues);

  const benefitPackServerToFrontEndConversionValue =
    (initialBenefitPack === 1 && 'Career Start') ||
    (initialBenefitPack === 3 && 'Tallavera') ||
    null;

  const benefitsPackValue = {
    id: benefitPackServerToFrontEndConversionValue,
    name: benefitPackServerToFrontEndConversionValue,
    value: initialBenefitPack,
  };

  const initialPayGroup = get(['payGroup'], initialValues);
  const payGroupValue = {
    id: initialPayGroup,
    name: initialPayGroup,
    value: initialPayGroup,
  };
  const navigate = useNavigate();

  const filledIconsColor = theme?.button?.palette?.whiteIcon;

  const handleJobButtonClick = () => {
    const url = `/${ROUTE_CONSTANTS.JOB_ORDERS}`;
    const ptParams = `corporation=${initialValues?.corporation?._id}&position=${initialValues?.positionInfo?.name}`;
    navigate(`${url}?${ptParams}`);
  };

  return (
    <RFForm
      onSubmit={handleOnSubmit}
      initialValues={{
        ...initialValues,
        branch: branchValue,
        legalBusEntry: legalBusEntryValue,
        payGroup: payGroupValue,
        benefitsPack: benefitsPackValue,
      }}
      render={({ handleSubmit, pristine }) => (
        <Box
          sx={{
            width: '100%',
            overflowY: 'auto',
            '&::-webkit-scrollbar': { display: 'none' },
            maxHeight: 'inherit',
          }}
        >
          <form
            onSubmit={handleSubmit}
            id="loginformid"
            style={{
              width: '100%',
              paddingBottom: pristine ? null : theme.spacing(3),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={{ ...viewHeaderStyling, margin: theme.spacing(0, 0, 1, 0) }}>
                Position Overview
              </Typography>

              <IconButton
                iconElement={
                  <FormatListBulletedIcon sx={{ color: filledIconsColor, fontSize: 30 }} />
                }
                onClick={handleJobButtonClick}
                size="small"
                sx={{ marginLeft: 0.5, marginTop: 0.5 }}
                labelSx={{
                  fontSize: '14px',
                  fontWeight: '500',
                  fontFamily: 'Barlow',
                  color: `${filledIconsColor}`,
                }}
                label={intl.formatMessage({ id: 'buttonText.goToJobs' })}
              />
            </Box>

            <Grid container spacing={1} direction="column" sx={{ width: '100%' }}>
              <Typography sx={fieldHeaderStyling}>Position Title</Typography>
              <Grid item>{buildFormField(formFieldData.name)}</Grid>
              <Typography sx={{ ...fieldHeaderStyling, margin: theme.spacing(2, 0, 1, 1) }}>
                Position Corporation
              </Typography>
              <Typography sx={readOnlyFieldValueStyling}>
                {get(['corporation', 'name'], initialValues)}
              </Typography>
              <Typography sx={fieldHeaderStyling}>Position Description</Typography>
              <Grid item>{buildFormField(formFieldData.description)}</Grid>
              <Typography sx={{ ...viewHeaderStyling, margin: theme.spacing(5, 0, 0, 1) }}>
                Is this job approved?
              </Typography>
              <Grid item>{buildFormField(formFieldData.approved)}</Grid>
              <Typography sx={{ ...viewHeaderStyling, margin: theme.spacing(4, 0, 0, 1) }}>
                Position Location
              </Typography>
              <Grid item>{buildFormField(formFieldData.locations)}</Grid>
              <Typography sx={{ ...viewHeaderStyling, margin: theme.spacing(4, 0, 0, 1) }}>
                Position Department
              </Typography>
              <Grid item>{buildFormField(formFieldData.departments)}</Grid>
              <Typography sx={{ ...viewHeaderStyling, margin: theme.spacing(4, 0, 2, 1) }}>
                Additional Details
              </Typography>
              <Typography sx={{ ...fieldHeaderStyling, margin: theme.spacing(1, 0, 1, 1) }}>
                {intl.formatMessage({ id: 'page.positionTemplateDetail.branch.label' })}
              </Typography>
              <Grid item>{buildFormField(formFieldData.branch)}</Grid>
              <Typography sx={{ ...fieldHeaderStyling, margin: theme.spacing(1, 0, 1, 1) }}>
                {intl.formatMessage({ id: 'page.positionTemplateDetail.legalBusEntry.label' })}
              </Typography>
              <Grid item>{buildFormField(formFieldData.legalBusEntry)}</Grid>
              <Typography sx={{ ...fieldHeaderStyling, margin: theme.spacing(1, 0, 1, 1) }}>
                {intl.formatMessage({ id: 'page.positionTemplateDetail.benefitsPack.label' })}
              </Typography>
              <Grid item>{buildFormField(formFieldData.benefitsPack)}</Grid>
              <Typography sx={{ ...fieldHeaderStyling, margin: theme.spacing(1, 0, 1, 1) }}>
                {intl.formatMessage({ id: 'page.positionTemplateDetail.payGroup.label' })}
              </Typography>
              <Grid item>{buildFormField(formFieldData.payGroup)}</Grid>
              <Typography sx={{ ...fieldHeaderStyling, margin: theme.spacing(1, 0, 1, 1) }}>
                {intl.formatMessage({ id: 'page.positionTemplateDetail.saleGroup.label' })}
              </Typography>
              <Grid item>{buildFormField(formFieldData.saleRep)}</Grid>
              <Button
                onClick={() => onDelete(true)}
                text={LanguageConverter('button.position.delete')}
                variant={BUTTON_VARIANT.DELETE}
                sx={{
                  width: '146px',
                  height: '32px',
                  margin: theme.spacing(4, 0, 2, 1),
                }}
              />
            </Grid>
            {pristine ? null : (
              <Box sx={buttonSx}>
                <Button
                  sx={{ width: '100%', height: buttonHeight }}
                  type={BUTTON_TYPE.SUBMIT}
                  text={LanguageConverter('button.submit')}
                />
              </Box>
            )}
          </form>
        </Box>
      )}
    />
  );
};

AdminOverview.propTypes = {
  availableLocations: PropTypes.arrayOf(PropTypes.shape({})),
  container: PropTypes.shape({}),
  initialValues: PropTypes.shape({
    corporation: PropTypes.shape({
      _id: PropTypes.string,
    }),
    positionInfo: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default AdminOverview;
