import { WAE_SUPPORTED_FILTER_FIELD_TYPES } from '@careerstart/wae-common/src/main/constants/filterQueries';

import FormSwitch from '../../../../../components/Form/FormSwitch';
import FormTextField from '../../../../../components/Form/FormTextField';
import {
  composeValidators,
  isGreaterThan,
  isInteger,
} from '../../../../../components/Form/validations';

export const requireEmployerApprovalField = {
  ComponentType: FormSwitch,
  name: 'requireEmployerApproval',
  label: 'requireEmployerApproval',
  labelKey: 'pt.create.requireEmployerApproval',
  labelPlacement: 'start',
};

export const allowBackfillField = {
  ComponentType: FormSwitch,
  name: 'allowBackfill',
  labelKey: 'pt.create.allowBackfill',
  labelPlacement: 'start',
};

export const allowOvertimeField = {
  ComponentType: FormSwitch,
  name: 'overtimeAllowed',
  labelPlacement: 'start',
  labelKey: 'pt.create.allowOvertime',
};

export const isGPSEnforcedField = {
  ComponentType: FormSwitch,
  name: 'gpsEnforced',
  labelPlacement: 'start',
  labelKey: 'pt.create.gps.title',
};

export const punchDistanceField = {
  ComponentType: FormTextField,
  label: 'Set Distance (measured in meters)',
  name: 'punchDistance',
  fullWidth: true,
  required: true,
  type: WAE_SUPPORTED_FILTER_FIELD_TYPES.NUMBER,
  mode: 'dark',
  variant: 'outlined',
  validate: composeValidators(isInteger, isGreaterThan(100)),
};
