import React, { useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ZendeskAPI } from 'react-zendesk';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import WAELogo from '../../../main-deprecated/assets/icons/LargeLogo.svg';
import ProfileMenu from '../../../main-deprecated/components/NavigationBar/SubComponents/ProfileMenu';
import stringAvatar from '../../../main-deprecated/components/NavigationBar/SubComponents/stringAvatar';
import selectUser from '../../../main-deprecated/store/selectors/appSelector';
import launchDarklyToggles from '../../../main-deprecated/utils/launchDarklyToggles';
import MarkChatUnreadIcon from '../../assets/icons/MarkChatUnreadIcon';
import SmsIcon from '../../assets/icons/SmsIcon';
import theme from '../../theme/theme';
import { ROUTE_CONSTANTS } from '../../views/app/routeConstants';

import {
  generateDropDownMenus,
  generateNavigationMenus,
} from './SubComponentsAndUtils/generateNavigationMenus';
import NavigationTab from './SubComponentsAndUtils/NavigationTab';
import RoleMenu from './SubComponentsAndUtils/RoleMenu';

const { SKILLS_AND_CERTS, CORPORATIONS, USERS, POSITION_TEMPLATES } = ROUTE_CONSTANTS;
const { ADMIN, RECRUITER } = UserRole;

const activeColor = `${theme?.components?.navigationBar?.palette?.activeColor}`;
const activeFont = `${theme?.components?.navigationBar?.typography?.active}`;
const inactiveColor = `${theme?.components?.navigationBar?.palette?.inactiveColor}`;
const inactiveFont = `${theme?.components?.navigationBar?.typography?.inactive}`;

const NavigationBar = ({ flags }) => {
  const user = useSelector(selectUser);
  const isEnterpriseDashboardEnabled = launchDarklyToggles(flags, 'isEnterpriseDashboardEnabled');
  const navigationMenus = generateNavigationMenus(user?.role, isEnterpriseDashboardEnabled);
  const roleMenusDropDown = generateDropDownMenus(user?.role);
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [roleMenuOpen, setRoleMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(false);
  const [zendeskUnreadMessageCount, setZendeskUnreadMessageCount] = useState(0);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleProfilePictureClick = (e) => {
    setAnchorEl(e.currentTarget);
    setProfileMenuOpen(!profileMenuOpen);
  };

  const handleRoleMenuClick = (e) => {
    setMenuAnchorEl(e.currentTarget);
    setRoleMenuOpen(!roleMenuOpen);
  };

  const handleProfileMenuClose = () => {
    setProfileMenuOpen(false);
  };

  const handleRoleMenuClose = () => {
    setRoleMenuOpen(false);
  };

  ZendeskAPI('messenger:on', 'unreadMessages', (count) => {
    if (count !== zendeskUnreadMessageCount) {
      setZendeskUnreadMessageCount(count);
    }
  });

  const isAdminMenuActive = [SKILLS_AND_CERTS, CORPORATIONS, USERS, POSITION_TEMPLATES].some(
    (str) => location.pathname?.includes(str)
  );
  return (
    <AppBar
      position="static"
      sx={{ bgcolor: theme?.components?.navigationBar?.palette?.backgroundColor }}
    >
      <Container maxWidth={false} sx={{ paddingLeft: 0 }}>
        <RoleMenu
          anchorEl={menuAnchorEl}
          open={roleMenuOpen}
          handleProfileMenuClose={handleRoleMenuClose}
          user={user}
          roleMenus={roleMenusDropDown}
        />
        <ProfileMenu
          anchorEl={anchorEl}
          open={profileMenuOpen}
          handleProfileMenuClose={handleProfileMenuClose}
          user={user}
        />
        <Toolbar disableGutters>
          <Box
            component="img"
            data-cy="app-logo"
            alt="WAE logo"
            sx={{ width: '88px', display: { xs: 'none', md: 'flex' }, mr: 1, marginRight: 5 }}
            src={WAELogo}
          />

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon color="primary" fontSize="large" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {navigationMenus.map((menu) => (
                <MenuItem
                  key={menu.id}
                  sx={{
                    ':hover': {
                      color: activeColor,
                      '& .MuiSvgIcon-root': {
                        color: activeColor,
                      },
                      '& .MuiTypography-root': {
                        color: activeColor,
                        fontFamily: activeFont,
                      },
                    },
                  }}
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate(menu.url);
                  }}
                >
                  <Typography textAlign="center">{menu.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
            }}
          >
            <Box
              component="img"
              data-cy="app-logo"
              alt="WAE logo"
              sx={{ width: '88px' }}
              src={WAELogo}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'flex-start',
              gap: 8,
            }}
          >
            {navigationMenus.map((menu) => (
              <NavigationTab
                key={menu.id}
                url={menu.url}
                label={menu.label}
                id={menu.id}
                Icon={menu.Icon}
              >
                {menu.label}
              </NavigationTab>
            ))}
            <Box>
              {(user.role === ADMIN || user.role === RECRUITER) && (
                <Box sx={{ flexGrow: 0, marginRight: 10 }}>
                  <Button
                    size="medium"
                    startIcon={
                      <AdminPanelSettingsIcon
                        sx={{
                          color: isAdminMenuActive ? activeColor : inactiveColor,
                        }}
                      />
                    }
                    onClick={handleRoleMenuClick}
                    sx={{
                      borderRadius: '25px',
                      marginRight: 1,
                      textTransform: 'capitalize',
                    }}
                    data-testid="role-button"
                  >
                    <Typography
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        color: isAdminMenuActive ? activeColor : inactiveColor,
                        fontFamily: isAdminMenuActive ? activeFont : inactiveFont,
                      }}
                    >
                      {user?.role}
                    </Typography>
                  </Button>
                </Box>
              )}
            </Box>
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            {(user.role === ADMIN || user.role === RECRUITER) && (
              <Box sx={{ flexGrow: 0 }}>
                <Button
                  size="medium"
                  startIcon={
                    <AdminPanelSettingsIcon
                      sx={{
                        color: isAdminMenuActive ? activeColor : inactiveColor,
                      }}
                    />
                  }
                  onClick={handleRoleMenuClick}
                  sx={{
                    borderRadius: '25px',
                    marginRight: 1,
                    textTransform: 'capitalize',
                  }}
                  data-testid="role-button-small"
                >
                  <Typography
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: isAdminMenuActive ? activeColor : inactiveColor,
                      fontFamily: isAdminMenuActive ? activeFont : inactiveFont,
                    }}
                  >
                    {user?.role}
                  </Typography>
                </Button>
              </Box>
            )}
          </Box>

          <Box sx={{ flexGrow: 0, display: 'flex', justifyContent: 'flex-start' }}>
            <Button
              size="medium"
              startIcon={
                zendeskUnreadMessageCount ? (
                  <MarkChatUnreadIcon
                    sx={{
                      color: inactiveColor,
                    }}
                  />
                ) : (
                  <SmsIcon
                    sx={{
                      color: inactiveColor,
                    }}
                  />
                )
              }
              sx={{
                borderRadius: '25px',
                marginRight: 1,
                textTransform: 'capitalize',
              }}
              onClick={() => ZendeskAPI('messenger', 'open')}
            >
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: inactiveColor,
                  fontFamily: inactiveFont,
                }}
              >
                {LanguageConverter('button.7/24Support')}
              </Typography>
            </Button>
            <Avatar
              {...stringAvatar(user)}
              sx={{ width: 40, height: 40 }}
              onClick={handleProfilePictureClick}
            />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

NavigationBar.propTypes = {
  flags: PropTypes.shape({}),
};
export default withLDConsumer()(NavigationBar);
