const FILTERS = {
  UPCOMING: [
    // { field: 'cancelled', operation: 'equals', value: false },
    // upcoming jobs
    { field: 'end', operation: 'after', value: +new Date() },
  ],
  COMPLETED: [
    // TODO deal with cancelled Jobs
    // completed
    { field: 'end', operation: 'before', value: +new Date() },
  ],

  NOT_CANCELLED: [{ field: 'cancelled', operation: 'equals', value: false }],

  LONG_TERM_JOBS: [
    // TODO deal with cancelled Jobs
    // longtermJobs
    { field: 'shiftGroup', operation: 'notEqual', value: null },
  ],
  DAY: [
    // TODO deal with cancelled Jobs
    // { field: 'shiftGroup', operation: 'equals', value: null },
  ],

  get UPCOMING_JOBS() {
    return [{ field: 'end', operation: 'after', value: +new Date() }];
  },

  get COMPLETED_JOBS() {
    return [{ field: 'end', operation: 'before', value: +new Date() }];
  },
};

const SEARCH_PARAM_KEYS = {
  CORPORATION: 'corporation',
  FILL_RATE: 'fullShifts',
  STATUS: 'status',
  SHIFT_START: 'shiftStart',
  SHIFT_END: 'shiftEnd',
  POSITION: 'position',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
};

const FILTER_FIELDS_TO_OPERATION = {
  corporation: { field: 'corporation', operation: 'equalsID' },
  fillRate: { field: 'full', operation: 'equals' },
  status: { field: 'cancelled', operation: 'equals' },
  shift: { field: 'shift', operation: 'betweenClockTimes' },
  position: { field: 'name', operation: 'icontains' },
  startDate: { field: 'start', operation: 'onOrAfter' },
  endDate: { field: 'end', operation: 'onOrBefore' },
};

const parseStringToBoolean = (value) => {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return null;
};

const FILTER_MAPPING = {
  [SEARCH_PARAM_KEYS.CORPORATION]: {
    field: FILTER_FIELDS_TO_OPERATION.corporation.field,
    operation: FILTER_FIELDS_TO_OPERATION.corporation.operation,
  },
  [SEARCH_PARAM_KEYS.FILL_RATE]: {
    field: FILTER_FIELDS_TO_OPERATION.fillRate.field,
    operation: FILTER_FIELDS_TO_OPERATION.fillRate.operation,
    convertValue: (value) => parseStringToBoolean(value),
  },
  [SEARCH_PARAM_KEYS.STATUS]: {
    field: FILTER_FIELDS_TO_OPERATION.status.field,
    operation: FILTER_FIELDS_TO_OPERATION.status.operation,
    convertValue: (value) => parseStringToBoolean(value),
  },
  [SEARCH_PARAM_KEYS.POSITION]: {
    field: FILTER_FIELDS_TO_OPERATION.position.field,
    operation: FILTER_FIELDS_TO_OPERATION.position.operation,
  },
};

const SHIFT_OPTION_KEYS = {
  FIVE_AM_TO_ONE_THIRTY_PM: '05:00 AM - 01:30 PM',
  ONE_THIRTY_PM_TO_NINE_THIRTY_PM: '01:30 PM - 09:30 PM',
  NINE_THIRTY_PM_TO_FIVE_AM: '09:30 PM - 05:00 AM',
};

export {
  FILTER_FIELDS_TO_OPERATION,
  FILTER_MAPPING,
  FILTERS,
  SEARCH_PARAM_KEYS,
  SHIFT_OPTION_KEYS,
};
