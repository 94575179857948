import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Chip, InputAdornment, ListItemText, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';

import theme from '../../theme';
import { PRIMARY_FONT } from '../../theme/fontConstants';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultipleSelectDropdownChip = ({
  disabled,
  placeholder,
  startAdornmentIcon,
  options,
  allOptionsLabel,
  onValueChange,
  defaultSelectedOptions = [],
}) => {
  const initialRenderRef = useRef(true);
  const [selectedOptions, setSelectedOptions] = React.useState(defaultSelectedOptions);
  const areAllSelected = selectedOptions.length === options.length;

  const styleForFormControl = {
    '& .MuiOutlinedInput-root': {
      padding: `${selectedOptions.length > 0 ? 0 : 2}px 2px !important`,
    },
    '& .MuiOutlinedInput-input': {
      paddingRight: '0px !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px',
    },
    border: `1px solid ${theme?.searchBoxForFilter?.borderColor?.focus}`,
    borderRadius: '5px',
    justifyContent: 'center',
    height: '28px',
    width: areAllSelected || selectedOptions.length === 0 ? '140px' : '290px',
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(typeof value === 'string' ? value.split(',') : value);
    if (onValueChange) {
      onValueChange(value);
    }
  };

  const handleClearSelection = () => {
    setSelectedOptions([]);
    if (onValueChange) {
      onValueChange([]);
    }
  };

  useEffect(() => {
    if (initialRenderRef.current) {
      initialRenderRef.current = false;
    } else if (!defaultSelectedOptions || defaultSelectedOptions.length === 0) {
      setSelectedOptions([]);
    }
  }, [defaultSelectedOptions]);

  return (
    <FormControl sx={{ ...styleForFormControl }}>
      <Select
        disabled={disabled}
        labelId="shift-time-label"
        id="shift-time-select"
        displayEmpty
        multiple
        value={selectedOptions}
        onChange={handleChange}
        input={
          <OutlinedInput
            id="select-multiple-checkbox"
            startAdornment={<InputAdornment position="start">{startAdornmentIcon}</InputAdornment>}
            endAdornment={
              selectedOptions.length > 0 ? (
                <ClearIcon
                  onClick={handleClearSelection}
                  style={{ cursor: 'pointer', width: '12px', marginLeft: '8px' }}
                />
              ) : (
                <AddIcon
                  style={{
                    color: theme?.button?.palette?.primary,
                    opacity: 1,
                    width: '13px',
                    height: '13px',
                    marginLeft: '2px',
                  }}
                />
              )
            }
          />
        }
        renderValue={(selected) => {
          if (!selected || selected.length === 0) {
            return (
              <span
                style={{
                  color: theme?.button?.palette?.primary,
                  fontFamily: PRIMARY_FONT['400'],
                  opacity: 1,
                }}
              >
                {placeholder}
              </span>
            );
          }
          if (areAllSelected) {
            return (
              <Chip
                label={allOptionsLabel}
                color="primary"
                sx={{
                  height: '24px',
                  fontSize: '14px',
                  borderRadius: '6px',
                  fontFamily: PRIMARY_FONT['400'],
                }}
              />
            );
          }
          return (
            <Box sx={{ display: 'flex', gap: '4px' }}>
              {selected.map((item) => (
                <Chip
                  key={item}
                  label={item}
                  color="primary"
                  sx={{
                    color: 'white',
                    height: '24px',
                    fontSize: '14px',
                    borderRadius: '6px',
                    fontFamily: PRIMARY_FONT['400'],
                  }}
                />
              ))}
            </Box>
          );
        }}
        MenuProps={MenuProps}
        IconComponent={() => null}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={selectedOptions.indexOf(option) > -1} />
            <ListItemText primary={<Typography>{option}</Typography>} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

MultipleSelectDropdownChip.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  startAdornmentIcon: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.string),
  allOptionsLabel: PropTypes.string,
  onValueChange: PropTypes.func,
  defaultSelectedOptions: PropTypes.arrayOf(PropTypes.string),
};

export default MultipleSelectDropdownChip;
