import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import DayJobSearchSchema from '@careerstart/wae-common/schema/job-order/job-search/day-jobs/post.req.json';
import LongTermJobSearchSchema from '@careerstart/wae-common/schema/job-order/job-search/long-term-jobs/post.req.json';
import jobTypes from '@careerstart/wae-common/src/main/constants/jobTypes';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { searchParamOptions } from '../../../../main/views/app/routeConstants';
import AlertBanner from '../../../components/Candidate/AlertBanner';
import DetailView from '../../../components/Candidate/DetailView/DetailView';
import GeoLocationBanner from '../../../components/Candidate/GeoLocationBanner';
import ShiftGroupCard from '../../../components/Candidate/ShiftGroupCard';
import { FreeSearchFilter } from '../../../components/Filters/FreeSearchFilter/FreeSearchFilter';
import SelectDropdownFilter from '../../../components/Filters/SelectDropdownFilter';
import { validateSchema } from '../../../components/Form/validations';
import LazyList from '../../../components/LazyList/LazyList';
import ScrollToTopButton from '../../../components/ScrollToTopButton';
import selectUser from '../../../store/selectors/appSelector';
import {
  selectDayGroupsCount,
  selectDayJobsPage,
  selectHasResetList,
  selectIsLoadingDayJobsList,
  selectIsLoadingLongJobsList,
  selectJobGroupsList,
  selectLongGroupsCount,
  selectLongJobsPage,
  selectSelectedGroup,
} from '../../../store/selectors/candidateJobsListSelectors';
import theme, { mobileViewContentHeight, navigationBarHeight } from '../../../theme';
import { useDims } from '../../../utils/customHooks';
import JobInvitation from '../jobInvitation/JobInvitation';

import AllJobsDetail from './Details/AllJobsDetail';
import { SelectMultipleDropdownForFilter } from './subComponent/SelectMultipleDropdownForFilter';
import SwitchFilter from './subComponent/SwitchFilter';
import CandidateEmptyCard from './CandidateEmptyCard';
import CandidateJobTypeTab from './CandidateJobTypeTab';
import { INDUSTRY_OPTIONS, JOB_TYPE_OPTIONS } from './constants';
import {
  getCandidateDayJobsList,
  getCandidateLongJobsList,
  resetCandidateJobsList,
  setSelectedGroup,
} from './reducer';
import ResponsiveFilter from './ResponsiveFilter';

const PAGE_SIZE = 10;

const AllJobsList = () => {
  const muiTheme = useTheme();
  const alertBannerTheme = theme?.candidate?.components?.alertBanner;
  const mediumScreen = useMediaQuery(muiTheme.breakpoints.up('md'));
  const phoneScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const dispatch = useDispatch();
  const selectedGroup = useSelector(selectSelectedGroup);

  const isLoadingDayJobsList = useSelector(selectIsLoadingDayJobsList);
  const isLoadingLongJobsList = useSelector(selectIsLoadingLongJobsList);

  const user = useSelector(selectUser);

  const jobGroupsList = useSelector(selectJobGroupsList);

  const dayJobPage = useSelector(selectDayJobsPage);
  const longJobPage = useSelector(selectLongJobsPage);

  const hasResetList = useSelector(selectHasResetList);

  const headerRef = useRef();
  const candidateCertifications = useMemo(() => user?.candidate?.certifications || [], [user]);

  const defaultValueForCandidateFilter = useMemo(
    () => ({
      value: candidateCertifications,
      field: 'certifications',
      operation: 'idIncludedIn',
    }),
    [candidateCertifications]
  );

  const [selectedFilters, setSelectedFilters] = useState({
    items: [defaultValueForCandidateFilter],
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedJobType =
    searchParams.get(searchParamOptions.JOB_TYPE) ||
    JOB_TYPE_OPTIONS.find((d) => d.value === 'day').jobType;
  const selectedLocationId = searchParams.get(searchParamOptions.LOCATION);
  const selectedPositionTemplateId = searchParams.get(searchParamOptions.POSITION);
  const selectedShiftGroupId = searchParams.get(searchParamOptions.SHIFT_GROUP);

  const longGroupsCount = useSelector(selectLongGroupsCount);
  const dayGroupsCount = useSelector(selectDayGroupsCount);
  const itemCount = useMemo(
    () => (selectedJobType === jobTypes.LONG ? longGroupsCount : dayGroupsCount),
    [selectedJobType, dayGroupsCount, longGroupsCount]
  );

  const shouldGetDayJobs = useMemo(
    () => selectedJobType === jobTypes.DAY && !isLoadingDayJobsList,
    [selectedJobType, isLoadingDayJobsList]
  );

  const shouldGetLongJobs = useMemo(
    () => selectedJobType === jobTypes.LONG && !isLoadingLongJobsList,
    [selectedJobType, isLoadingLongJobsList]
  );

  const closeDetailView = useCallback(() => {
    setSearchParams({ [searchParamOptions.JOB_TYPE]: selectedJobType });
    dispatch(setSelectedGroup({}));
  }, [dispatch, setSearchParams, selectedJobType]);

  const workCardClickHandler = useCallback(
    (jobGroup) => {
      if (jobGroup._id !== selectedGroup._id) {
        dispatch(setSelectedGroup(jobGroup));
        setSearchParams({
          [searchParamOptions.LOCATION]: jobGroup.location?._id,
          [searchParamOptions.POSITION]: jobGroup.positionTemplate,
          [searchParamOptions.SHIFT_GROUP]: jobGroup?.multipleShiftCommitment,
          [searchParamOptions.JOB_TYPE]: selectedJobType,
        });
      }
    },
    [dispatch, setSearchParams, selectedGroup._id, selectedJobType]
  );

  const JobGroupCard = useCallback(
    ({ item }) => (
      <ShiftGroupCard
        data={item}
        key={item?._id}
        itemClickHandler={workCardClickHandler}
        selected={item._id === selectedGroup._id}
        id={item?._id}
        title={item?.title}
        corporationName={item?.corporationName}
        corporationId={item?.corporationId}
        totalPay={item?.totalPay}
        payRateReg={item?.payRateReg}
        multipleShiftCommitment={item?.multipleShiftCommitment}
        location={item?.location}
      />
    ),
    [selectedGroup._id, workCardClickHandler]
  );

  const getNextData = useCallback(() => {
    const req = {
      page: shouldGetLongJobs ? longJobPage : dayJobPage,
      filters:
        selectedFilters && selectedFilters.items && selectedFilters.items.length > 0
          ? selectedFilters.items
          : undefined,
      limit: PAGE_SIZE,
      sortBy: [{ field: 'start', descending: true }],
    };

    if (shouldGetDayJobs && Object.keys(validateSchema(req, DayJobSearchSchema)).length === 0) {
      dispatch(getCandidateDayJobsList(req));
    }
    if (
      shouldGetLongJobs &&
      Object.keys(validateSchema(req, LongTermJobSearchSchema)).length === 0
    ) {
      dispatch(getCandidateLongJobsList(req));
    }
  }, [dispatch, dayJobPage, longJobPage, shouldGetDayJobs, shouldGetLongJobs, selectedFilters]);

  useEffect(() => {
    if (hasResetList) {
      getNextData();
    }
  }, [getNextData, hasResetList]);

  useEffect(() => {
    dispatch(resetCandidateJobsList());
  }, [dispatch]);

  const onFilterChange = useCallback(
    (filterVal) => {
      // if jobType is changed update the url
      const jobType = filterVal?.items.find((item) => item.field === 'jobType')?.value;

      // update ONLY if filter differs from previous
      if (JSON.stringify(filterVal) !== JSON.stringify(selectedFilters)) {
        setSearchParams((prev) => ({
          ...prev,
          [searchParamOptions.JOB_TYPE]: jobType || selectedJobType,
        }));

        const validFilters = filterVal?.items.filter((item) => !!item.operation);
        setSelectedFilters({ items: validFilters });

        dispatch(resetCandidateJobsList());
      }
    },
    [dispatch, setSelectedFilters, setSearchParams, selectedJobType, selectedFilters]
  );

  const customFilter = [
    {
      field: 'jobType',
      getOptionLabel: (option) => option?.name,
      options: JOB_TYPE_OPTIONS,
      placeholder: 'Job type',
      type: SelectDropdownFilter,
      initialValue: {
        value: JOB_TYPE_OPTIONS.find((option) => option.value === selectedJobType),
      },
      disableClearable: true,
    },
    {
      field: 'certifications',
      operation: 'idIncludedIn',
      fixedValue: candidateCertifications,
      type: SwitchFilter,
      allowEmptyArray: true,
    },
    {
      field: 'industry',
      operation: 'includedIn',
      reformatValue: (value) => value.map((v) => v.value),
      multiple: true,
      getOptionLabel: (option) => option?.name,
      options: INDUSTRY_OPTIONS,
      placeholder: 'Industry',
      type: SelectMultipleDropdownForFilter,
    },
    /* TODO: uncomment once backend is working!
     {
       field: 'distance',
       operation: 'near',
       getOptionLabel: (option) => get(['name'], option),
       options: DISTANCE_OPTIONS,
       placeholder: 'Distance',
       reformatValue: (value) => ({
         latitude: 43.149703, // need to be updated to user geoLocation
         longitude: -77.58833,
         distance: MILES_TO_METERS(value),
       }),
       type: SelectDropdownFilter,
     },
     */
    {
      field: 'name',
      operation: 'icontains',
      placeholder: 'Group',
      type: FreeSearchFilter,
    },
  ].filter((item) => !!item);

  const containerRef = useRef();
  const containerDims = useDims(containerRef);

  useEffect(() => {
    if (!selectedGroup._id) {
      const selectedJob = jobGroupsList.find(
        (row) =>
          row?.location?._id === selectedLocationId &&
          row?.positionTemplate === selectedPositionTemplateId &&
          row?.multipleShiftCommitment === (selectedShiftGroupId === 'true')
      );

      if (selectedJob) {
        dispatch(setSelectedGroup(selectedJob));
      }
    }
  }, [
    dispatch,
    selectedLocationId,
    selectedPositionTemplateId,
    selectedShiftGroupId,
    jobGroupsList,
    selectedGroup._id,
  ]);

  return (
    <Grid
      sx={{
        height: phoneScreen ? `calc(100vh - ${navigationBarHeight})` : mobileViewContentHeight,
        paddingTop: navigationBarHeight,
      }}
      container
    >
      <Slide
        direction="right"
        unmountOnExit
        in={!(!mediumScreen && !!selectedGroup.positionTemplate && !!selectedGroup.location)}
        timeout={10}
        easing={{ enter: 'step-end', exit: 'step-start' }}
      >
        <Grid
          item
          container
          md
          xs
          sm
          sx={{
            height: 'inherit',
            marginRight: !!selectedGroup.positionTemplate && !!selectedGroup.location && 2,
            overflowY: 'auto',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          <Box sx={{ maxWidth: '100%' }}>
            <div ref={headerRef}>
              <CandidateJobTypeTab initVal="allJobs" id="typeTab" />
            </div>
            <Box sx={{ width: '100%', margin: theme.spacing(2, 0, 2, 0) }}>
              <GeoLocationBanner />
              <AlertBanner
                LeftIcon={WarningAmberIcon}
                CloseIcon={CloseIcon}
                message="Earnings displayed are pre-tax and do not include any withhold amounts. The final amount
          received may be different after taxes and other deductions have been taken out."
                backgroundColor={alertBannerTheme?.backgroundColor}
                messageSx={{
                  color: alertBannerTheme?.fontColor,
                  fontFamily: alertBannerTheme?.font,
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                marginBottom: '15px',
              }}
              ref={containerRef}
            >
              <ResponsiveFilter
                items={customFilter}
                onFilterChange={onFilterChange}
                forceCollapse={!!selectedGroup.positionTemplate && !!selectedGroup.location}
                containerDims={containerDims}
              />
            </Box>
            {(shouldGetDayJobs && dayJobPage === 0) || (shouldGetLongJobs && longJobPage === 0) ? (
              <Grid
                sx={{
                  width: '100%',
                  height: '95%',
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: '15%',
                }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid sx={{ width: '100%' }}>
                <LazyList
                  Card={JobGroupCard}
                  getNextData={getNextData}
                  items={jobGroupsList}
                  itemCount={itemCount}
                  skeletonVariant="Text"
                  skeletonBackgroundColor={theme.components.detailView.palette.lightLazyLoadColor}
                  NoDataCard={CandidateEmptyCard}
                />

                <ScrollToTopButton headerRef={headerRef} />
              </Grid>
            )}
          </Box>
        </Grid>
      </Slide>

      {selectedGroup.positionTemplate && selectedGroup.location && (
        <DetailView Content={AllJobsDetail} close={closeDetailView} />
      )}

      {searchParams.get(searchParamOptions.INVITED) && <JobInvitation />}
    </Grid>
  );
};

export default withLDConsumer()(AllJobsList);
